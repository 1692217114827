export const environment = {
    production: true,
    amb: 'DEV'
};

//Coregir ambiente de produccion luego de corregir los ENV
let apiUrl = "https://captaciones-ext-dev.apps.ambientesbc.com/seguros-para-empleados/seguros/";
let apiUrlMok = "https://captaciones-ext-dev.apps.ambientesbc.com/seguros-para-empleados/api/v1/";

export const endpoint = {
  tiposIdentificacion: apiUrl + "tiposidentificacion",
  tiposParentesco: apiUrl + "tiposparentesco",
  empleados:  apiUrl + "empleado",
  empleadoshr: apiUrl + "empleadohr",
  ciudades: apiUrl + "ciudades",
  estados: apiUrl + "estados",
  generos: apiUrl + "generos",
  estadosCiviles: apiUrl + "marital-status",
  eps: apiUrl + "eps",
  clasesvehiculo: apiUrl + "clasesvehiculo",
  fasecolda: apiUrl + "fasecolda",
  cotizacion: apiUrl + "cotizacion/vehiculos",
  cotizacionLife: apiUrl + "cotizacion/vida",
  cotizacionHealth: apiUrl + "cotizacion/salud",
  planes: apiUrl + "planes",
  certificado: apiUrl + "certificado-salud",

  cotizacionAsistencia: apiUrlMok + "retrieve/plan-asistencia/AUTOS_M001",
  expedicionAsistencia: apiUrlMok + "transactions",
}

export const msal = {
    clientId: "302d77f4-1c51-4e3c-8cc1-930e8e6a9d66",
    redirectUri: "https://segemple-dev.apps.ambientesbc.com/",
    tenant: '428f4e2e-13bf-4884-b364-02ef9af41a1d'
}
