import { Injectable } from '@angular/core';
import ListResponse from '../dto/list-response.dto';
import { DocumentTypeModel } from '../model/DocumentType.model';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { endpoint } from 'src/environments/environment';
import { RelationshipTypeModel } from '../model/RelationshipType.model';
import { EmployeeModel } from '../model/Employee.model';
import Response from '../dto/response.dto';
import { SelectGeneralTypeModel } from '../model/GeneralType.model';
import { SelectGeneralTypeModel2 } from '../model/GeneralType2.model';

@Injectable({
    providedIn: 'root'
})
export class PersonalInformationService {

    private endpointDocumentType: string;
    private endpointRelationshipType: string;
    private endpointGenderType: string;
    private endpointMaritalType: string;
    private endpointEpsType: string;
    private endpointEmpleadoInfo: string;
    private endpointEmpleadoCertificate: string;
    private endpointEmpleadoInfoHR: string; 

    constructor(private http: HttpClient){
        this.endpointDocumentType = endpoint.tiposIdentificacion;
        this.endpointRelationshipType = endpoint.tiposParentesco;
        this.endpointEmpleadoInfo = endpoint.empleados;
        this.endpointGenderType = endpoint.generos;
        this.endpointMaritalType = endpoint.estadosCiviles;
        this.endpointEpsType = endpoint.eps;
        this.endpointEmpleadoCertificate = endpoint.certificado;
        this.endpointEmpleadoInfoHR = endpoint.empleadoshr; 
    }

    /* Servicio reemplazado por getInformationFromHR
    getEmployeeInformation(): Observable<EmployeeModel> {
        return this.http.get<Response<EmployeeModel>>(`${this.endpointEmpleadoInfo}`)
            .pipe(map((res: Response<EmployeeModel>) => { return res.data }));
    }
    */

    getDocumentTypes(): Observable<Array<DocumentTypeModel>> {
        return this.http.get<ListResponse<DocumentTypeModel>>(this.endpointDocumentType)
            .pipe(map((res: ListResponse<DocumentTypeModel>) => { return res.data }));
    }

    getRelationshipTypes(): Observable<Array<RelationshipTypeModel>> {
        return this.http.get<ListResponse<RelationshipTypeModel>>(this.endpointRelationshipType)
            .pipe(map((res: ListResponse<RelationshipTypeModel>) => { return res.data }));
    }
    getGenderTypes(): Observable<Array<SelectGeneralTypeModel>> {
        return this.http.get<ListResponse<SelectGeneralTypeModel>>(this.endpointGenderType)
            .pipe(map((res: ListResponse<SelectGeneralTypeModel>) => { return res.data }));
    }
    getMaritalTypes(): Observable<Array<SelectGeneralTypeModel2>> {
        return this.http.get<ListResponse<SelectGeneralTypeModel2>>(this.endpointMaritalType)
            .pipe(map((res: ListResponse<SelectGeneralTypeModel2>) => { return res.data }));
    }
    getEpsTypes(): Observable<Array<SelectGeneralTypeModel2>> {
        return this.http.get<ListResponse<SelectGeneralTypeModel2>>(this.endpointEpsType)
            .pipe(map((res: ListResponse<SelectGeneralTypeModel2>) => { return res.data }));
    }

    getCertificate(documento: string):Observable<any>{
      return this.http.get(`${this.endpointEmpleadoCertificate}?documento=${encodeURIComponent(documento)}`,{responseType:'blob' as 'json'});
    }

    getInformationFromHR(data: any):Observable<EmployeeModel>{
        return this.http.post<Response<EmployeeModel>>(this.endpointEmpleadoInfoHR, data)
      .pipe(map((res: Response<EmployeeModel>) => { return res.data }));
    }

}
