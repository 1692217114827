import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/inicio'
    },{
        path: 'inicio',
        canLoad: [MsalGuard],
        canActivate: [MsalGuard],
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    },{
      path: 'autos',
      loadChildren: () => import('./modules/autos/autos.module').then(m => m.AutosModule),
      canLoad: [MsalGuard],
      canActivate: [MsalGuard],
      data: {
        breadcrumbs: {
          path: '/inicio',
          text: 'Inicio',
        }
      }
    },{
      path: 'vida',
      loadChildren: () => import('./modules/life/life.module').then(m => m.LifeModule),
      canLoad: [MsalGuard],
      canActivate: [MsalGuard],
      data: {
        breadcrumbs: {
          path: '/inicio',
          text: 'Inicio',
        }
      }
    },{
      path: 'asistencia',
      loadChildren: () => import('./modules/assistance/assistance.module').then(m => m.AssistanceModule),
      canLoad: [MsalGuard],
      canActivate: [MsalGuard],
      data: {
        breadcrumbs: {
          path: '/inicio',
          text: 'Inicio',
        }
      }
    },{
      path: 'salud',
      loadChildren: () => import('./modules/health/health.module').then(m => m.HealthModule),
      canLoad: [MsalGuard],
      canActivate: [MsalGuard],
      data: {
        breadcrumbs: {
          path: '/inicio',
          text: 'Inicio',
        }
      }
    },{
      path: '**',
        redirectTo: 'inicio'
    }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Don't perform initial navigation in iframes
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
